(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/banner_common/banner-helpers/assets/javascripts/dynamic-jackpots.js') >= 0) return;  svs.modules.push('/components/banner_common/banner-helpers/assets/javascripts/dynamic-jackpots.js');

'use strict';

const isServer = typeof exports === 'object';
const trinidadCore = isServer && require('trinidad-core').core || {};
const trindadSharedComponents = isServer && trinidadCore.components.require('content', 'shared') || {};

const gameDescription = {
  lottoOne: 'Lotto 1',
  lottoSecond: 'Lotto 2',
  dreamWin: 'Drömvinsten',
  eurojackpot: 'Eurojackpot',
  vikinglotto: 'Vikinglotto'
};

const editSchemaJackpotNameHeader = {
  lottoOne: '{{lottoJackpotOneHeader}}',
  lottoSecond: '{{lottoJackpotSecondHeader}}',
  dreamWin: '{{dreamWinJackpotHeader}}',
  eurojackpot: '{{EuroJackpotHeader}}',
  vikinglotto: '{{vikingJackpotHeader}}'
};

const editSchemaJackpotNameBody = {
  lottoOne: '{{lottoJackpotOneBody}}',
  lottoSecond: '{{lottoJackpotSecondBody}}',
  dreamWin: '{{dreamWinJackpotBody}}',
  eurojackpot: '{{EuroJackpotBody}}',
  vikinglotto: '{{vikingJackpotBody}}'
};

const getJackpotDataBannerServerSide = async (products, request, logger) => {
  try {
    return await trindadSharedComponents.jackpots.getJackpots({
      request,
      products,
      logger
    });
  } catch (error) {
    logger.info('Failed to get jackpots serverside', error, request);
  }
  return [];
};

const getJackpotDataBannerClientSide = async (products, logger) => {
  try {
    return await svs.content.shared.getJackpots(products);
  } catch (error) {
    logger.info('Failed to get jackpots clientside', error);
  }
  return [];
};

const getJackpotDataBanner = async (request, logger) => {
  const products = ['lotto', 'eurojackpot', 'vikinglotto'];
  if (isServer) {
    return getJackpotDataBannerServerSide(products, request, logger);
  }
  return getJackpotDataBannerClientSide(products, logger);
};

const jackpotAmountTextFormatted = (bannerText, schemaJackpotName, jackpotAmountText) => {
  if (bannerText.indexOf(schemaJackpotName) === 0) {
    return jackpotAmountText.charAt(0).toUpperCase() + jackpotAmountText.slice(1);
  }
  return jackpotAmountText;
};

const replaceEditSchemaValuesToDynamic = (schemaJackpotName, bannerText, jackpotAmountText) => bannerText.replaceAll(schemaJackpotName, jackpotAmountTextFormatted(bannerText, schemaJackpotName, jackpotAmountText));

const setDynamicValues = (reformattedText, jackpotDescription, schemaJackpotName, jackpotAmountText) => {
  const replaceValues = schemaName => replaceEditSchemaValuesToDynamic(schemaName, reformattedText, jackpotAmountText);
  switch (jackpotDescription) {
    case gameDescription.lottoOne:
      return replaceValues(schemaJackpotName.lottoOne);
    case gameDescription.lottoSecond:
      return replaceValues(schemaJackpotName.lottoSecond);
    case gameDescription.dreamWin:
      return replaceValues(schemaJackpotName.dreamWin);
    case gameDescription.eurojackpot:
      return replaceValues(schemaJackpotName.eurojackpot);
    case gameDescription.vikinglotto:
      return replaceValues(schemaJackpotName.vikinglotto);
    default:
      return reformattedText;
  }
};

const cleanTextAfterFormat = reformattedText => {
  const regexFindAllCurlyBraces = /\{\{.*\}\}/gi;
  return reformattedText.replaceAll(regexFindAllCurlyBraces, '');
};

const createBannerJackpotAmount = (jackpotAmountString, defaultJackpot) => {
  const jackpotAmount = parseInt(jackpotAmountString, 10);
  const prefixString = {
    fixedJackpot: 'minst',
    dynamicJackpot: 'ca'
  };
  const formatJackpotAmountString = (formatAmount, jackpotString, doubleDigitJackpotString) => {
    const formattedJackpotAmount = jackpotAmount / formatAmount;
    const formattedJackpotPrefix = defaultJackpot ? prefixString.fixedJackpot : prefixString.dynamicJackpot;
    const formattedJackpotSuffix = formattedJackpotAmount === 1 ? jackpotString : doubleDigitJackpotString;
    return "".concat(formattedJackpotPrefix, " ").concat(formattedJackpotAmount, " ").concat(formattedJackpotSuffix);
  };
  const formatAmounts = {
    million: 1000000,
    milliard: 1000000000
  };
  const valueString = {
    milliard: 'miljard',
    milliards: 'miljarder',
    million: 'miljon',
    millions: 'miljoner'
  };
  if (jackpotAmount >= formatAmounts.milliard) {
    return formatJackpotAmountString(formatAmounts.milliard, valueString.milliard, valueString.milliards);
  } else if (jackpotAmount >= formatAmounts.million) {
    return formatJackpotAmountString(formatAmounts.million, valueString.million, valueString.millions);
  }
  return jackpotAmountString;
};


const formatDynamicJackpotText = _ref => {
  let {
    dynamicJackpotValues,
    bannerText,
    isHeading
  } = _ref;
  let reformattedText = bannerText;
  const schemaJackpotName = isHeading ? editSchemaJackpotNameHeader : editSchemaJackpotNameBody;
  dynamicJackpotValues.forEach(jackpotObject => {
    if (jackpotObject !== null && jackpotObject !== void 0 && jackpotObject.jackpotAmount) {
      const jackpotAmountText = createBannerJackpotAmount(jackpotObject.jackpotAmount, jackpotObject.defaultJackpot) || '';
      const jackpotDescription = (jackpotObject === null || jackpotObject === void 0 ? void 0 : jackpotObject.description) || '';
      reformattedText = setDynamicValues(reformattedText, jackpotDescription, schemaJackpotName, jackpotAmountText);
    }
  });
  return cleanTextAfterFormat(reformattedText);
};

const dynamicValueExists = (header, body) => {
  const regexFindCurlyBraces = /\{\{.*\}\}/i;
  return regexFindCurlyBraces.test(header) || regexFindCurlyBraces.test(body);
};

const replaceDynamicJackpotValue = async function (req, logger) {
  let header = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '';
  let body = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : '';
  if (dynamicValueExists(header, body)) {
    const dynamicJackpotValues = await getJackpotDataBanner(req, logger);
    header = formatDynamicJackpotText({
      dynamicJackpotValues,
      bannerText: header,
      isHeading: true
    });
    body = formatDynamicJackpotText({
      dynamicJackpotValues,
      bannerText: body,
      isHeading: false
    });
  }
  return {
    reformattedHeading: header,
    reformattedBody: body
  };
};
if (svs.isServer) {
  module.exports = {
    replaceDynamicJackpotValue
  };
} else {
  svs.banner_common.banner_helpers.replaceDynamicJackpotValue = replaceDynamicJackpotValue;
}

 })(window);